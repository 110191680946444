import { useStaticQuery, graphql } from 'gatsby'

export default () => {
    const payload = useStaticQuery(
        graphql`
            query {
                wpPage(
                    title: { eq: "Privacy Policy" }
                ) {
                    privacy_page {
                        privacyEnglishHeader
                        privacyEnglish
                        privacyGermanHeader
                        privacyGerman
                    }
                }
            }
        `
    )
    return payload
}
