import React from 'react'
import Layout from '../components/layout'
import Privacy from '../components/privacy'

const PrivacyPage = () => (
    <Layout>
        <Privacy />
    </Layout>
)

export default PrivacyPage
