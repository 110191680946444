import React, { useState } from 'react'
import { injectSanitiseHtml } from '../utils'
import usePrivacyData from './custom-hooks/usePrivacyData'

import * as privacyStyles from './privacy.module.scss'

const ImprintPage = () => {
    const payload = usePrivacyData()

    const language = {
        ENGLISH: 'English',
        GERMAN: 'Deutsch',
    }

    const headers = {
        ENGLISH: `${payload.wpPage.privacy_page.privacyEnglishHeader}.`,
        GERMAN: `${payload.wpPage.privacy_page.privacyGermanHeader}.`,
    }

    const descriptions = {
        ENGLISH: injectSanitiseHtml(payload.wpPage.privacy_page.privacyEnglish),
        GERMAN: injectSanitiseHtml(payload.wpPage.privacy_page.privacyGerman),
    }

    const getBlock = blockType => {
        if (categoryState === language.ENGLISH) {
            return blockType.ENGLISH
        }
        if (categoryState === language.GERMAN) {
            return blockType.GERMAN
        }
        return blockType.ENGLISH
    }

    const [categoryState, setCategory] = useState(language.ENGLISH)

    return (
        <div className={privacyStyles.privacyContainer}>
            <h1 className={privacyStyles.title}>{getBlock(headers)}</h1>
            <div className={privacyStyles.setters}>
                {Object.values(language).map(lang => (
                    <p
                        className={
                            lang === categoryState
                                ? privacyStyles.activeText
                                : privacyStyles.passiveText
                        }
                        onClick={() => setCategory(lang)}
                    >
                        {lang}
                    </p>
                ))}
            </div>
            <div className={privacyStyles.description}>
                {getBlock(descriptions)}
            </div>
        </div>
    )
}

export default ImprintPage
